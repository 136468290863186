import { type FC } from 'react'

import { inject } from 'mobx-react'

import {
    CollapsibleContent,
    CollapsibleInfoCard,
    CollapsibleTogglerAction,
    InfoCardDetails,
    infoCardDetailsGap,
    type InfoCardDetailType,
    InfoCardHeader,
} from 'components'
import { type AuthStore } from 'core/auth'
import { CostCenterRow } from 'resources/costCenters'
import { DivisionRow } from 'resources/divisions'
import { type UnitModel } from 'resources/units'
import { uppercaseFormat } from 'utils'

import { getDetails } from './utils'

const getBasicDetails: (auth: AuthStore) => InfoCardDetailType<UnitModel>['details'] = (auth) => [
    ...(auth.companySettings.hasDomicile
        ? [
              {
                  label: 'Domicile',
                  source: ({ domicileData }) => domicileData?.name,
              },
          ]
        : []),
    ...(auth.companySettings.hasCostCenters
        ? [
              {
                  label: 'Cost Center',
                  source: ({ costCenterData }) => <CostCenterRow record={costCenterData} />,
              },
          ]
        : []),
    ...(auth.companySettings.hasDivisions
        ? [
              {
                  label: 'Division',
                  source: ({ divisionData }) => <DivisionRow record={divisionData} />,
              },
          ]
        : []),
    {
        label: 'Unit Name',
        source: 'name',
    },
    {
        label: 'VIN',
        source: 'vin',
    },
    {
        label: 'Serial Number',
        source: 'serialNumber',
    },
    {
        label: 'License Plate',
        source: ({ licensePlate }) => uppercaseFormat(licensePlate),
    },
]

const BasicDetails: FC<{ maxVisibleColumns: number }> = inject('auth')(({
    auth,
    maxVisibleColumns,
}: {
    auth: AuthStore
} & { maxVisibleColumns: number }) => {
    const { visibleDetails, collapsibleDetails } = getDetails(
        getBasicDetails(auth),
        maxVisibleColumns,
    )
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title="Basic Details"
                action={collapsibleDetails.length ? <CollapsibleTogglerAction /> : undefined}
            />
            <InfoCardDetails<UnitModel> details={visibleDetails} />
            {collapsibleDetails.length ? (
                <CollapsibleContent>
                    <InfoCardDetails<UnitModel>
                        mt={infoCardDetailsGap}
                        details={collapsibleDetails}
                    />
                </CollapsibleContent>
            ) : null}
        </CollapsibleInfoCard>
    )
})

export default BasicDetails
