import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { createdField } from 'resources/base'
import {
    inventoryItemsResource,
    type PartInInventoryModel,
    type InventoryPricingModel,
    inventoryPricingFields,
} from 'resources/inventory'
import { PageContent } from 'ui'

import { InventoryPricingDrawerToggler } from './InventoryPricingDrawerToggler'
import ListHeader from './InventoryPricingListHeader'
import { inventoryPricingPreferencesResource } from './utils'

const defaultSort: SortPayload<InventoryPricingModel> = {
    field: 'created',
    order: Order.DESC,
}

const sortConfig: ListSortContentProps<InventoryPricingModel> = {
    sortBy: [
        createdField.sort(),
        inventoryPricingFields.partTier.sort(),
        inventoryPricingFields.type.sort(),
        inventoryPricingFields.acquisitionCost.sort(),
        inventoryPricingFields.costFloor.sort(),
        inventoryPricingFields.markupPercent.sort(),
        inventoryPricingFields.marginPercent.sort(),
        inventoryPricingFields.markup.sort(),
        inventoryPricingFields.price.sort(),
    ],
}

const columnsConfig: DatagridColumnsProps<InventoryPricingModel> = {
    checkboxSelection: false,
    columns: [
        inventoryPricingFields.partTier.tableColumn(),
        inventoryPricingFields.type.tableColumn(),
        inventoryPricingFields.acquisitionCost.tableColumn(),
        inventoryPricingFields.costFloor.tableColumn(),
        inventoryPricingFields.markupPercent.tableColumn(),
        inventoryPricingFields.marginPercent.tableColumn(),
        inventoryPricingFields.markup.tableColumn(),
        inventoryPricingFields.price.tableColumn(),
    ],
    actions: ({ row }, { children, resource }) => [
        <InventoryPricingDrawerToggler
            key="edit"
            record={row}
            resource={resource.resource}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick,
                })
            }
        </InventoryPricingDrawerToggler>,
    ],
}

const cardsConfig: CardListConfig<InventoryPricingModel> = {
    titleSource: (record) => inventoryPricingFields.partTier.value(record),
    defaultImage: null,
    disableTitleLink: true,
    details: [
        inventoryPricingFields.type.dataCardRow(),
        inventoryPricingFields.acquisitionCost.dataCardRow(),
        inventoryPricingFields.costFloor.dataCardRow(),
        inventoryPricingFields.markupPercent.dataCardRow(),
        inventoryPricingFields.marginPercent.dataCardRow(),
        inventoryPricingFields.markup.dataCardRow(),
        inventoryPricingFields.price.dataCardRow(),
    ],
    actions: (record, { children, resource }) => [
        <InventoryPricingDrawerToggler
            resource={resource.resource}
            key="edit"
            record={record}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    key: 'edit',
                    onClick,
                })
            }
        </InventoryPricingDrawerToggler>,
    ],
}

const InventoryPricing = () => {
    const record = useRecordContext<PartInInventoryModel>()
    const isLoading = !record
    return (
        <PageContent>
            <ResourceContextProviderWithClearEffect
                value={{
                    ...inventoryPricingPreferencesResource,
                    resource: `${inventoryItemsResource.resource}/${record?.inventoryItem?.id}/pricing`,
                }}
            >
                <ListBase
                    sort={defaultSort}
                    isLoading={isLoading}
                    preferencesResource={inventoryPricingPreferencesResource}
                >
                    <ListHeader />
                    <List
                        preferencesResource={inventoryPricingPreferencesResource}
                        listFTUProps={{
                            linkText: '',
                        }}
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        disableManageColumns
                        disableExportButton
                    />
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        </PageContent>
    )
}

export default InventoryPricing
