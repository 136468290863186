import { type FC, type ReactElement, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useEditContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { TextInput, EmailInput, useUtilityDrawerContext, CheckboxInput } from 'components'
import { type AuthStore } from 'core'
import { memberFields, membersResource } from 'resources/members'
import { RoleInput } from 'resources/roles'
import { ShopInput } from 'resources/shops'

const ShopsInputWrapper = ({ children }: { children: ReactElement }) => {
    const { watch, setValue } = useFormContext()
    const shops = watch('shops')
    const { isFetching } = useEditContext()
    const isWrongData = typeof shops?.[0] === 'object'

    useEffect(() => {
        if (isWrongData && !isFetching) {
            const data = shops.map(({ id }) => id)
            setTimeout(() => setValue('shops', data), 1)
        }
    }, [isWrongData, isFetching])

    if (isWrongData) {
        return null
    }
    return children
}
const MemberForm: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { extraArgs } = useUtilityDrawerContext()
        return (
            <>
                <EmailInput
                    label={memberFields.email.label}
                    disabled
                />

                <TextInput
                    source={memberFields.name.source}
                    label={memberFields.name.label}
                    disabled
                />
                <RoleInput required />
                <ShopsInputWrapper>
                    <ShopInput
                        contextType={membersResource.name}
                        contextId={extraArgs.id}
                        source="shops"
                        multiple
                        required
                        defaultValue={false}
                    />
                </ShopsInputWrapper>

                {auth.companySettings.hasDomicile ? (
                    <CheckboxInput
                        source={memberFields.viewUnitsWithoutDomicile.source}
                        label={memberFields.viewUnitsWithoutDomicile.label}
                    />
                ) : null}
            </>
        )
    }),
)

export default MemberForm
