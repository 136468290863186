import { type FC } from 'react'

import { inject } from 'mobx-react'

import { type Identifier } from 'appTypes'
import { AutocompleteInput, type SelectInputProps } from 'components'
import { ReferenceArrayInput, ReferenceInput, requiredValidation } from 'core'
import { type AuthStore } from 'core/auth'

import { shopResource } from '../utils'

interface ShopInputProps
    extends Pick<SelectInputProps, 'disabled' | 'multiple' | 'source' | 'helperText' | 'onChange'> {
    contextType: string
    defaultValue?: boolean
    contextId?: Identifier
    label?: string
    filter?: Record<string, any>
    required?: boolean
}

const ShopInput: FC<ShopInputProps> = inject('auth')(({
    required,
    disabled,
    multiple,
    source = 'shop',
    contextType,
    contextId,
    auth,
    defaultValue: defaultValueProp = true,
    label,
    helperText,
    filter,
    onChange,
}: ShopInputProps & { auth: AuthStore }) => {
    const Component = multiple ? ReferenceArrayInput : ReferenceInput

    const defaultValue = defaultValueProp ? auth.shopId : null

    return (
        <Component
            source={source}
            reference={shopResource.resource}
            disabled={disabled}
            perPage={100}
            queryOptions={{
                meta: { query: { contextType, contextId } },
            }}
            filter={filter}
        >
            <>
                <AutocompleteInput
                    helperText={helperText}
                    label={label || `Repair shop${multiple ? 's' : ''}`}
                    validate={required ? requiredValidation : undefined}
                    multiple={multiple}
                    disabled={disabled || Boolean(defaultValue)}
                    optionText="name"
                    defaultValue={defaultValue ? auth.shopId : null}
                    filterSelectedOptions
                    onChange={onChange}
                />
            </>
        </Component>
    )
})

export default ShopInput
