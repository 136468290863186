import { type FC, useEffect } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import { type AuthStore } from 'core'

const storeShop = (shopId: Identifier | null) => {
    if (shopId) {
        sessionStorage.setItem('shop', shopId as string)
        localStorage.setItem('shop', shopId as string)
    } else {
        sessionStorage.setItem('shop', '')
        localStorage.setItem('shop', '')
    }
}

// the shop should be stored only if the tab is on focus
export const StoreShop: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const shopId = auth.shopId
        console.log('shopId', shopId)

        useEffect(() => {
            const cb = () => {
                if (document.hidden) {
                    return
                }
                storeShop(auth.shopId)
            }
            document.addEventListener('visibilitychange', cb)

            return () => {
                document.removeEventListener('visibilitychange', cb)
            }
        }, [])

        useEffect(() => {
            if (!document.hasFocus()) {
                return
            }
            storeShop(shopId)
        }, [shopId])

        return null
    }),
)

export const getStoredShop = (): string => {
    sessionStorage.getItem('shop') || localStorage.getItem('shop')
    const sessionShop = sessionStorage.getItem('shop')
    if (typeof sessionShop === 'string') {
        return sessionShop
    }

    const localShop = localStorage.getItem('shop')

    return typeof localShop === 'string' ? localShop : ''
}

export const initializeShopStore = () => {
    const shop = localStorage.getItem('shop')
    sessionStorage.setItem('shop', shop || '')
}
