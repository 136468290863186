import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import {
    List,
    type DatagridColumnsProps,
    ListAvatar,
    CardCollapsibleContent,
    ListFilterDateRangeValueInput,
    ListFilterRangeInput,
    useDistanceLabel,
    formatMoney,
    costMaskParams,
    resolveIntegerSpacedMask,
} from 'components'
import { useArchivedContext } from 'context'
import {
    deleteManyFromListAction,
    deleteOneAction,
    multiselectAction,
    editRedirectInListAction,
    archiveManyFromListAction,
    archiveOneAction,
    authStore,
    type AuthStore,
} from 'core'
import { formatDate, useFlags, globalClassNames, type Theme } from 'lib'
import { costCenterFilter, CostCenterRow, costCenterSort } from 'resources/costCenters'
import { divisionFilter, DivisionRow, divisionSort } from 'resources/divisions'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, TagsField } from 'resources/tags'
import { displayCK2, displayCK34, displayCK47 } from 'resources/vmrs'
import { Box, Typography } from 'ui'
import { uppercaseFormat } from 'utils'

import { archiveUnitActionParams, deleteUnitAction } from '../actions'
import unitFields from '../fields'
import { type MeterModel } from '../meters'
import { type UnitModel } from '../types'

import { TitleWithTelematicsStatus } from './TitleWithTelematicsStatus'
import UnitDrawerToggler from './UnitDrawerToggler'

import type { GridCellParams } from '@mui/x-data-grid'
import type {
    CardListConfig,
    FilterConfig,
    ListBulkActions,
    ListSortContentProps,
} from 'components'

const DefaultIcon: FC<{ isArchived: boolean }> = ({ isArchived }) => {
    return <unitFields.avatar.Icon sx={isArchived ? { opacity: 0.54 } : undefined} />
}

const dataAvatarColor = (isArchived: boolean) =>
    isArchived ? (theme: Theme) => theme.palette.text.primary : undefined

const bodyTypeSource = 'bodyVmrsType' as keyof UnitModel

const getLastMetersByType = (params: GridCellParams, type: MeterModel['type']) => {
    const meter = params.row.lastMeters?.find((o) => o.type === type)
    return meter
}

const removedColumnsMap = [
    'vmrsManufacturer',
    'tireSize',
    'engineVmrsManufacturer',
    'engineModel',
    'transmissionVmrsManufacturer',
    'transmissionModel',
    'transmissionGears',
    'lastOdometerDate',
    'lastEngineHoursDate',
    'lastHubometer',
    'lastHubometerDate',
    'engineHp',
]

interface UnitColumns extends UnitModel {
    lastOdometerDate: string
    lastEngineHoursDate: string
    lastHubometerDate: string
}

interface UnitsListBaseProps {
    auth?: AuthStore
    removeFilters?: boolean
    disableExportButton?: boolean
}
const UnitsListBase: FC<UnitsListBaseProps> = inject('auth')(
    observer(({ auth, removeFilters, disableExportButton }) => {
        const { isArchived } = useArchivedContext()
        const hasCustomers = auth.companySettings.hasCustomers
        const distanceLabel = useDistanceLabel({ textCase: 'capital' })
        const record = useRecordContext()
        const { flag621UnitListAllColumns } = useFlags()

        const columns: DatagridColumnsProps<UnitColumns>['columns'] = [
            {
                field: 'photo',
                headerName: 'Avatar',
                maxWidth: 72,
                renderCell: (cell) => (
                    <ListAvatar
                        linkProps={{
                            'aria-label': `View Unit with unit number ${cell.row.number}`,
                        }}
                        id={cell.id}
                        imageSrc={cell.value}
                        color={dataAvatarColor(isArchived)}
                        defaultImage={<DefaultIcon isArchived={isArchived} />}
                    />
                ),
            },
            {
                field: 'number',
                headerName: 'Unit Number',
                flex: 1,
                renderCell: ({ row }) => {
                    return <TitleWithTelematicsStatus record={row} />
                },
            },
            unitFields.domicile.tableColumn({
                auth,
                dataToValue: (row: UnitModel) => row.domicileData?.name,
            }),
            { field: 'name', headerName: 'Name', flex: 1 },
            isArchived && {
                field: 'archived',
                headerName: 'Archived On',
                renderCell: (cell) => {
                    return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
                },
            },
            {
                field: 'licensePlate',
                headerName: 'License Plate',
                valueFormatter: ({ value }) => uppercaseFormat(value),
            },
            { field: 'vin', headerName: 'VIN', flex: 1 },
            { field: 'serialNumber', headerName: 'Serial Number' },
            {
                field: 'vmrsManufacturer',
                headerName: 'Manufacturer/Make',
                renderCell: ({ row }) => displayCK34(row.vmrsManufacturerData),
            },
            {
                field: 'vmrsEquipmentCategory',
                headerName: 'Equipment Category',
                renderCell: ({ row }) => displayCK2(row.vmrsEquipmentCategoryData),
            },
            {
                field: bodyTypeSource,
                headerName: 'Body Type',
                renderCell: ({ row }) => displayCK47(row.body?.vmrsTypeData),
            },
            { field: 'model', headerName: 'Model', flex: 1 },
            { field: 'modelYear', headerName: 'Model Year', flex: 1 },
            { field: 'color', headerName: 'Color', flex: 1 },
            { field: 'tireSize', headerName: 'Tire Size', flex: 1 },
            {
                field: 'engineVmrsManufacturer',
                headerName: 'Engine Make',
                renderCell: ({ row }) => displayCK34(row.engineVmrsManufacturerData),
            },
            { field: 'engineModel', headerName: 'Engine Model', flex: 1 },
            { field: 'engineHp', headerName: 'Engine HP', flex: 1 },
            {
                field: 'transmissionVmrsManufacturer',
                headerName: 'Transmission Make',
                renderCell: ({ row }) => displayCK34(row.transmissionVmrsManufacturerData),
            },
            { field: 'transmissionModel', headerName: 'Transmission Model', flex: 1 },
            { field: 'transmissionGears', headerName: 'Transmission Gears', flex: 1 },
            unitFields.status.tableColumn(),
            {
                field: 'unresolvedDefects',
                headerName: 'Unresolved Defects',
                valueGetter: ({ value }) => value,
            },
            { field: 'pmIntervalsCount', headerName: 'PM Intervals' },
            { field: 'openIssuesCount', headerName: 'Open Issues' },
            {
                field: 'lastOdometer',
                headerName: 'Odometer (Last Known)',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'ODOMETER')
                    return meter ? resolveIntegerSpacedMask(meter.value) : null
                },
            },
            {
                field: 'lastOdometerDate',
                headerName: 'Odometer Date',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'ODOMETER')
                    return formatDate(
                        meter?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'lastEngineHours',
                headerName: 'Engine Hours (Last Known)',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'ENGINE_HOURS')
                    return meter ? resolveIntegerSpacedMask(meter.value) : null
                },
            },
            {
                field: 'lastEngineHoursDate',
                headerName: 'Engine Hours Date',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'ENGINE_HOURS')
                    return formatDate(
                        meter?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'lastHubometer',
                headerName: 'Hubometer (Last Known)',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'HUBOMETER')
                    return meter ? resolveIntegerSpacedMask(meter.value) : null
                },
            },
            {
                field: 'lastHubometerDate',
                headerName: 'Hubometer Date',
                renderCell: (params) => {
                    const meter = getLastMetersByType(params, 'HUBOMETER')
                    return formatDate(
                        meter?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'total',
                headerName: 'Maintenance Cost',
                headerAlign: 'right',
                align: 'right',
                renderCell: ({ value }) => formatMoney(value),
            },
            authStore.companySettings.hasCostCenters
                ? {
                      field: 'costCenter',
                      headerName: 'Cost Center',
                      renderCell: ({ row, value }) =>
                          value ? (
                              <Box className={globalClassNames.ellipsis}>
                                  <CostCenterRow record={row.costCenterData} />
                              </Box>
                          ) : null,
                  }
                : null,
            authStore.companySettings.hasDivisions
                ? {
                      field: 'division',
                      headerName: 'Division',
                      renderCell: ({ row, value }) =>
                          value ? (
                              <Box className={globalClassNames.ellipsis}>
                                  <DivisionRow record={row.divisionData} />
                              </Box>
                          ) : null,
                  }
                : null,
            {
                field: 'costPerDistance',
                headerName: `Cost per ${distanceLabel}`,
                align: 'right',
                headerAlign: 'right',

                renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
            },
            {
                field: 'costPerHour',
                headerName: 'Cost per Engine Hour',
                align: 'right',
                headerAlign: 'right',

                renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
            },
            {
                field: 'created',
                headerName: 'Created on',
                renderCell: (cell) => {
                    return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
                },
            },
            hasCustomers && {
                field: 'customer',
                headerName: 'Customer',
                renderCell: (cell) => {
                    return cell.row.customerData?.name
                },
            },
            {
                field: 'tagsData',
                headerName: 'Tags',
                renderCell: ({ value }) => <TagsField tags={value} />,
            },
        ]

        const columnsConfig: DatagridColumnsProps<UnitColumns> = {
            resetColumns: {
                vmrsEquipmentCategory: false,
                color: false,
                costPerDistance: false,
                costPerHour: false,
                pmIntervalsCount: false,
                vmrsManufacturer: false,
                tireSize: false,
                engineVmrsManufacturer: false,
                engineModel: false,
                engineHp: false,
                lastOdometerDate: false,
                lastEngineHoursDate: false,
                lastEngineHours: false,
                lastHubometerDate: false,
                lastHubometer: false,
                transmissionVmrsManufacturer: false,
                transmissionModel: false,
                transmissionGears: false,
                unresolvedDefects: false,
                tagsData: false,
                serialNumber: false,
                [bodyTypeSource]: false,
                costCenter: false,
                division: false,
            },
            constantColumns: {
                number: true,
            },
            columns: columns.filter((item) => {
                if (!flag621UnitListAllColumns) {
                    return item && !removedColumnsMap.includes(item?.field)
                }
                return item
            }),
            actions: ({ row: unit }, { children }) => [
                editRedirectInListAction({
                    children,
                    id: unit.id,
                }),
                archiveOneAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(unit.telematicsData),
                        unit.hasOpenWorkOrders,
                    ),

                    children,
                    id: unit.id,
                }),
                isArchived
                    ? deleteOneAction({
                          children,
                          id: unit.id,
                          ...deleteUnitAction(unit.hasRelatedWorkOrders),
                      })
                    : null,
            ],
        }

        const filters: FilterConfig<UnitModel>['filters'] = [
            {
                id: 'created',
                label: 'Created on',
                filterType: 'range',
                renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
            },
            { id: 'number', label: 'Unit Number' },
            unitFields.domicile.filter({
                auth,
            }),
            { id: 'name', label: 'Name' },
            { id: 'vin', label: 'VIN' },
            { id: 'serialNumber', label: 'Serial Number' },
            { id: 'licensePlate', label: 'License Plate' },
            unitFields.status.filter(),
            !isArchived && { id: 'unresolvedDefects', label: 'Unresolved Defects' },
            {
                id: 'pmIntervalsCount',
                label: 'PM Intervals',
            },
            {
                id: 'openIssuesCount',
                label: 'Open Issues',
            },
            { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
            { id: bodyTypeSource, label: 'Body Type' },
            { id: 'vmrsManufacturer', label: 'Manufacturer/Make' },
            { id: 'model', label: 'Model' },
            { id: 'modelYear', label: 'Model Year' },
            { id: 'engineVmrsManufacturer', label: 'Engine Make' },
            { id: 'engineModel', label: 'Engine Model' },
            { id: 'engineHp', label: 'Engine HP' },
            { id: 'transmissionVmrsManufacturer', label: 'Transmission Make' },
            { id: 'transmissionModel', label: 'Transmission Model' },
            { id: 'transmissionGears', label: 'Transmission Gears' },
            { id: 'color', label: 'Color' },
            { id: 'tireSize', label: 'Tire Size' },
            {
                id: 'total',
                label: 'Maintenance Cost',
                filterType: 'range' as const,
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        inputProps={costMaskParams}
                        {...props}
                    />
                ),
            },
            auth.companySettings.hasCostCenters && costCenterFilter(),
            auth.companySettings.hasDivisions && divisionFilter(),
            {
                id: 'costPerDistance',
                label: `Cost per ${distanceLabel}`,
                filterType: 'range' as const,
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        inputProps={costMaskParams}
                        {...props}
                    />
                ),
            },
            {
                id: 'costPerHour',
                label: 'Cost per Engine Hour',
                filterType: 'range' as const,
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        inputProps={costMaskParams}
                        {...props}
                    />
                ),
            },
            { id: 'tags', label: 'Tags' },
            hasCustomers && { id: 'customer', label: 'Customer' },
        ]

        const details: CardListConfig<UnitModel>['details'] = [
            unitFields.domicile.dataCardRow({
                auth,
                dataToValue: (row: UnitModel) => row.domicileData?.name,
            }),
            { source: 'name', label: 'unit name' },
            {
                source: 'licensePlate',
                label: 'license plate',
                render: (value) => uppercaseFormat(value),
            },
            { source: 'vin', label: 'vin' },
            { source: 'serialNumber', label: 'serial number' },
            { source: 'model', label: 'model' },
            { source: 'modelYear', label: 'model year' },
            { source: 'color', label: 'color' },
            hasCustomers && {
                source: 'customer',
                label: 'Customer',
                render: (v, record) => record.customerData?.name,
            },
        ]

        const cards: CardListConfig<UnitModel> = {
            titleSource: (record) => <TitleWithTelematicsStatus record={record} />,
            disableTitleLink: true,
            subTitleSource: (record) => unitFields.status.value(record),
            avatarColor: dataAvatarColor(isArchived),
            avatarOpacity: isArchived ? 0.12 : undefined,
            imageSource: 'photo',
            defaultImage: <DefaultIcon isArchived={isArchived} />,
            collapsibleContent: (record) => (
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <NotesCollapsibleIconHolder
                                    notes={record.notes}
                                    key="notesIcon"
                                />
                            ),
                            component: (
                                <NotesCollapsibleContent
                                    notes={record.notes}
                                    key="notesContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <TagsCollapsibleIconHolder
                                    tags={record.tagsData}
                                    key="tagsIcon"
                                />
                            ),
                            component: (
                                <TagsCollapsibleContent
                                    tags={record.tagsData}
                                    key="tagsContent"
                                />
                            ),
                        },
                    ]}
                />
            ),
            details: details.filter(Boolean),
            actions: (
                { id, telematicsData, hasRelatedWorkOrders, hasOpenWorkOrders },
                { children },
            ) => [
                editRedirectInListAction({
                    children,
                    id,
                }),
                // exportAction({
                //     children,
                // }),
                multiselectAction({
                    children,
                    id,
                }),
                archiveOneAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(telematicsData),
                        hasOpenWorkOrders,
                    ),
                    children,
                    id,
                }),
                isArchived
                    ? deleteOneAction({
                          children,
                          id,
                          ...deleteUnitAction(hasRelatedWorkOrders),
                      })
                    : null,
            ],
        }

        const sortBy: ListSortContentProps<UnitModel>['sortBy'] = [
            isArchived && {
                id: 'archived',
                label: 'Archived on',
            },
            { id: 'created', label: 'Created on' },
            { id: 'number', label: 'Unit Number' },
            unitFields.domicile.sort({
                auth,
            }),
            { id: 'name', label: 'Name' },
            { id: 'licensePlate', label: 'License Plate' },
            { id: 'vin', label: 'VIN' },
            { id: 'serialNumber', label: 'Serial Number' },
            { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
            { id: bodyTypeSource, label: 'Body Type' },
            { id: 'model', label: 'Model' },
            { id: 'modelYear', label: 'Model Year' },
            { id: 'color', label: 'Color' },
            unitFields.status.sort(),
            !isArchived && { id: 'unresolvedDefects', label: 'Unresolved Defects' },
            { id: 'pmIntervalsCount', label: 'PM Intervals' },
            { id: 'openIssuesCount', label: 'Open Issues' },
            { id: 'total', label: 'Maintenance Cost' },
            auth.companySettings.hasCostCenters && costCenterSort(),
            auth.companySettings.hasDivisions && divisionSort(),
            { id: 'costPerDistance', label: `Cost per ${distanceLabel}` },
            { id: 'costPerHour', label: 'Cost per Engine Hour' },
            auth.companySettings.hasCustomers && { id: 'customer', label: 'Customer' },
        ]

        const bulkActions: ListBulkActions = ({ children, listContext }) => {
            return [
                isArchived
                    ? deleteManyFromListAction({
                          children,
                          ...deleteUnitAction(
                              listContext.selectedIds.some(
                                  (selectedId) =>
                                      listContext.data.find(({ id }) => selectedId === id)
                                          ?.hasRelatedWorkOrders,
                              ),
                          ),
                      })
                    : null,
                archiveManyFromListAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(
                            listContext.data.find(
                                (unit) =>
                                    unit.telematicsData &&
                                    listContext.selectedIds.includes(unit.id),
                            ),
                        ),
                        listContext.selectedIds.some(
                            (selectedId) =>
                                listContext.data.find(({ id }) => selectedId === id)
                                    ?.hasOpenWorkOrders,
                        ),
                    ),
                    children,
                }),
            ]
        }

        return (
            <List
                disableExportButton={disableExportButton}
                bulkActions={bulkActions}
                sortCfg={{ sortBy: sortBy.filter(Boolean) }}
                columnsCfg={columnsConfig}
                cardsCfg={cards}
                filtersCfg={removeFilters ? null : { filters: filters.filter(Boolean) }}
                listFTUProps={{
                    linkText: isArchived ? (
                        ''
                    ) : (
                        <UnitDrawerToggler defaultValues={{ customer: record?.id }}>
                            {({ onClick }) => (
                                <Typography
                                    variant="body1"
                                    onClick={onClick}
                                    color={(theme) => theme.palette.primary.main}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    Add New Unit{' '}
                                </Typography>
                            )}
                        </UnitDrawerToggler>
                    ),
                    linkAction: (e) => {
                        e.preventDefault()
                    },
                    secondaryTitle: isArchived ? '' : 'Would you like to add one?',
                }}
            />
        )
    }),
)

export default UnitsListBase
