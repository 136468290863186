import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { Show, tabRoutes } from 'components'
import { type AuthStore } from 'core'
import { Routes } from 'lib'
import { ContactsListPage } from 'resources/contacts'

import { CustomerPricing } from '../CustomerPricing'
import { CustomerOverview } from '../Overview'
import { CustomerUnits } from '../Units'
import { customerContactsResourceName } from '../config'

import { ShowHeader } from './components'

const CustomerShow: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { tabs, routes } = tabRoutes([
            {
                value: '',
                label: 'Overview',
                element: <CustomerOverview />,
            },
            {
                value: 'contact-persons',
                label: 'Contact persons',
                element: <ContactsListPage resourceName={customerContactsResourceName} />,
            },
            {
                value: 'units',
                label: 'Units',
                element: <CustomerUnits />,
            },
            auth.companySettings.hasInvoicing && {
                value: 'customer-pricing',
                label: 'Customer Pricing',
                element: <CustomerPricing />,
            },
        ])

        return (
            <Show>
                <>
                    <ShowHeader tabs={tabs} />
                    <Routes>{routes}</Routes>
                </>
            </Show>
        )
    }),
)

export default CustomerShow
