import { inject, observer } from 'mobx-react'

import { urls } from 'configs'
import { AnonymousRequired, LoginRequired, type AuthStore } from 'core'
import { Route, Routes as RouterRoutes, ScrollToTopOnRouteChange, RemoveTrailingSlash } from 'lib'
import { StoreShop } from 'resourcesBase'

import {
    AcceptInvitation,
    LogoutInvitation,
    Deactivated,
    Login,
    ResetPassword,
    ResetPasswordConfirm,
    ResetPasswordDone,
} from './Auth'
import ChangePlan from './ChangePlan'
import Company from './Company'
import Configure from './Configure'
import Customers from './Customers'
import Dashboard from './Dashboard'
import Deactivate from './Deactivate'
import Inventory from './Inventory'
import Invoices from './Invoices'
import Issues from './Issues'
import NotFound from './NotFound'
import Parts from './Parts'
import PaymentHistory from './PaymentHistory'
import Profile from './Profile'
import PurchaseOrders from './PurchaseOrders'
import RepairShops from './RepairShops'
import Reports from './Reports'
import Units from './Units'
import VendorParts from './VendorParts'
import Vendors from './Vendors'
import WorkOrders from './WorkOrders'
import { MainLayout } from './layouts'

const Routes = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing
        return (
            <>
                <RemoveTrailingSlash />
                <RouterRoutes>
                    <Route
                        path={urls.root}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Dashboard />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    <Route
                        path={urls.workOrders + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <WorkOrders />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.issues + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Issues />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.units + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Units />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.unitsArchived + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Units />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    {auth.companySettings.hasCustomers && (
                        <Route
                            path={urls.customers + '/*'}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <Customers />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}
                    <Route
                        path={urls.parts + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Parts />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.vendorParts + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <VendorParts />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    {auth.companySettings.hasInventory && (
                        <Route
                            path={urls.inventory + '/*'}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <Inventory />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}
                    {auth.companySettings.hasInvoicing && (
                        <Route
                            path={urls.invoices + '/*'}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <Invoices />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}
                    {auth.companySettings.hasInventory && (
                        <Route
                            path={urls.purchaseOrders + '/*'}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <PurchaseOrders />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}
                    <Route
                        path={urls.reports + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Reports />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.configure + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Configure />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    <Route
                        path={urls.userProfile + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Profile />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.company + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Company />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    {billing && (
                        <Route
                            path={urls.changePlan}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <ChangePlan />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}

                    {billing && (
                        <Route
                            path={urls.paymentHistory}
                            element={
                                <LoginRequired
                                    redirect={() =>
                                        billing.subscription === 'pro' ? false : urls.company
                                    }
                                >
                                    <MainLayout>
                                        <PaymentHistory />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}

                    <Route
                        path={urls.login}
                        element={
                            <AnonymousRequired>
                                <Login />
                            </AnonymousRequired>
                        }
                    />

                    <Route
                        path={urls.passwordReset}
                        element={
                            <AnonymousRequired>
                                <ResetPassword />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.passwordResetDone}
                        element={
                            <AnonymousRequired>
                                <ResetPasswordDone />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.passwordResetConfirm}
                        element={
                            <AnonymousRequired>
                                <ResetPasswordConfirm />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.invitationAuth}
                        element={<LogoutInvitation />}
                    />
                    <Route
                        path={urls.invitationsAccept}
                        element={
                            <AnonymousRequired>
                                <AcceptInvitation />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.deactivate}
                        element={
                            <AnonymousRequired>
                                <Deactivate />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.deactivated}
                        element={
                            <AnonymousRequired>
                                <Deactivated />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.vendors + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Vendors />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    <Route
                        path={urls.repairShops + '/:id/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <RepairShops />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    {/* <Route
                        path="test"
                        element={(
                            <LoginRequired><MainLayout><TestPage /></MainLayout></LoginRequired>
                        )}
                    /> */}

                    <Route
                        path="*"
                        element={<NotFound />}
                    />
                </RouterRoutes>
                <StoreShop />
                <ScrollToTopOnRouteChange />
            </>
        )
    }),
)

export default Routes
